import React, { createContext, useState, useEffect } from 'react';
import {
  createClient,
  Provider,
  defaultExchanges,
  subscriptionExchange,
} from 'urql';
import { useIntercom } from 'react-use-intercom';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const graphqlEndpoint = 'hasura.growth-hacking.io/v1/graphql';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const intercom = useIntercom();
  const [auth, setAuth] = useState({ loading: true, data: null });

  const [databaseLoading, setDatabaseLoading2] = useState(false)

  const setDatabaseLoading = (data) => {
    console.log("Setting DB loader", data)
    setDatabaseLoading2(data)
  }

  const subscriptionClient = new SubscriptionClient(
    `wss://${graphqlEndpoint}`,
    {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${auth?.data?.accessToken}`,
          // 'x-hasura-role': 'user',
        },
      },
    }
  );

  const client = createClient({
    url: `https://${graphqlEndpoint}`,
    exchanges: [
      ...defaultExchanges,
      subscriptionExchange({
        forwardSubscription(operation) {
          return subscriptionClient.request(operation);
        },
      }),
    ],
    requestPolicy: 'network-only',
    // fetch: fetch,
    fetchOptions: () => {
      if (!auth) {
        return true;
      }
      return {
        headers: {
          Authorization: `Bearer ${auth?.data?.accessToken}`,
        },
      };
    },
  });

  const setAuthData = (data) => {
    setAuth({ data: data });
  };

  useEffect(() => {
    try {
      setAuth({
        loading: false,
        data: JSON.parse(localStorage.getItem('authData')),
      });
    } catch (e) {
      console.log('auth failed');
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('authData', JSON.stringify(auth.data));
    // intercom.show();
    intercom.boot();
    if (auth?.data) {
      intercom.update({
        userId: auth?.data?.id,
        name: auth?.data?.name,
        email: auth?.data?.email,
        createdAt: auth?.data?.createdAt,
      });
    } else {
      intercom.update({
        userId: undefined,
        name: undefined, // Full name
        email: undefined,
        createdAt: undefined,
      });
    }
  }, [auth.data]);

  return (
    <AuthContext.Provider value={{ auth, setAuthData, databaseLoading, setDatabaseLoading }}>
      <Provider value={client}>{children}</Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;

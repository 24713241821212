import React, { lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PrivateRoute from './privateRoute';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import packageJson from '../package.json';
import { Toaster } from 'react-hot-toast';

global.appVersion = packageJson.version;

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const CreateAccount = lazy(() => import('./pages/CreateAccount'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA?.split(/\./g);

  const versionsB = versionB?.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA?.shift());

    const b = Number(versionsB?.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

function App() {
  useEffect(() => {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );

          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            console.log('Invalidating cache..!');
            caches.keys().then(function (names) {
              for (let name of names) caches.delete(name);
            });
          } // delete browser cache and hard reload
          // window.location.reload(true);
          window.location.reload();
          // setLoading(false);
          // setLatestVersion(false);
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          // setLoading(false);
          // setLatestVersion(true);
        }
      });
  }, []);

  return (
    <>
      <Router>
        <Toaster />
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/a/login" component={Login} />
          <Route path="/a/register" component={CreateAccount} />
          <Route path="/a/forgot-password" component={ForgotPassword} />
          <Route path="/a/reset" component={ResetPassword} />

          {/* Place new routes over this */}
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
